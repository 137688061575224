import React from 'react';
import {Icon, Modal, Button, message, Input, InputNumber } from 'antd';
import {IconTypes} from '@/constants/common';
import PropTypes from 'prop-types';
import _ from "lodash";
import QRCode from 'qrcode.react';
import moment from "moment";
import {API_RechargeTradeOrderCreat,API_UserLevelChanger,API_TradeOrderCheckState} from "@/api/user";
import {convertPrice, copyUrlToBoard} from '@/components/common/common.functions'
import PB, {SimplePB} from "@/libs/simplePB";

import style from '@/style/components/accountCenter/account.bindWeChatAccountModal.less';
import { color } from 'highcharts';

class MemberRechargeModal extends React.Component {
  wxLoginHandler = undefined;

  state = {
    recharge_money: 600
  };

  shak=false;

  payMemberModal = null;
  setTimeoutCheckState = null;
  checkStateNum = 0;

  vip_content = '详情咨询，请添加炬图客服官方微信（微信号：JoinmapAI）。';


  /**
     * 会员充值
     * @param callback 购买后的回调 true=购买成功，false=购买失败
     */
  payRecharge = () => {
    let me = this;
    if(me.shak){
      return ;
    }
    if(me.state.recharge_money<=0){
      message.info('请输入正确的充值金额');
    }
    me.shak = true;
    let params ={
      trade_type: 1,
      order_type: 2,
      product_price: me.state.recharge_money*100,
      id_user: localStorage.getItem('userId')
    }
    API_RechargeTradeOrderCreat(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          let trade = response.data.data;
          me.doPayRechargeModal(trade); //扫码支付
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起充值请求。', 3);
      }
    }).catch((e) => {
      console.log('common.viewManager->payView->获取订单出错 e=', e);
      // console.log('common.viewManager->payView->获取订单出错 view=', view)
      // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
      message.error('网络不稳定，请稍后重新发起充值请求。', 3)
      //callback && callback({action: 'pay', viewId: view.viewId, success: false})
    });
    setTimeout(()=>{
      me.shak=false;},1000);
  }

  doDeduction = (trade_no) => {
    let me = this;
    if(me.shak){
      return ;
    }
    me.shak = true;
    let params ={
      trade_no:trade_no,
      id_user: localStorage.getItem('userId')
    }
    API_UserLevelChanger(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          message.info('操作成功！'+me.vip_content);
          me.props.onCancel();
          PB.emit('account', 'get.user_level', {});
        }else{
          message.info(response.data.msg, 3);
        }
      }else{
        message.info('网络不稳定，请稍后重新发起购买请求。', 3);
      }
    }).catch((e) => {
      message.error('网络不稳定，请稍后重新发起购买请求。', 3)
    });
    setTimeout(()=>{
      me.shak=false;},1000);
  }


  doPayRechargeModal  = (trade) => {
    let me = this;
    me.payMemberModal = Modal.confirm({
      centered: true,
      title: "会员充值",
      icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
      okText: "我已支付成功",
      cancelText: "取消",
      width: 650,
      content:
          <div style={{
              height: '100%',
              width: '100%',
              minHeight: '300px',
              paddingTop: '0px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
          }}>
              <div style={{
                  flex: '1',
                  height: '100%',
                  display: 'flex',
                  paddingTop: '20px'
                  //alignItems: 'center',
              }}>
                  <div style={{flex: '1',lineHeight:2}}>
                      <div>订单：<b>{trade.trade_no}</b></div>
                      <div>标题：<b>{"炬图-会员充值"}</b></div>
                      {trade.total_fee == trade.product_price && <div>金额：<b>{convertPrice(trade.total_fee)}元</b></div>}
                      {trade.total_fee != trade.product_price && <div>金额：<b>{convertPrice(trade.total_fee)}元(<span style={{textDecoration:'line-through'}}>{convertPrice(trade.product_price)}</span>元)</b></div>}
                      
                      <div><br/></div>
                  </div>
                  <div style={{flex: '0 1 35%', textAlign: 'center'}}>
                      <span><Icon name="wechat" style={{fontSize: '1rem', color: 'green'}}/> 微信扫码支付</span>
                      <div style={{
                          padding: '10px 10px 5px 10px ',
                          margin: '0 auto',
                          maxWidth: '150px',
                          textAlign: 'center',
                      }}>
                          <QRCode value={trade.code_url} title={'请使用微信扫码支付'}></QRCode>
                      </div>
                      <span>交易金额：<b style={{color: 'red'}}>{convertPrice(trade.total_fee)} 元</b><br/> </span>
                  </div>
              </div>
              <div style={{flex: '0 1 30%'}}>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>成功</b>，请点击 <b
                      style={{color: 'red'}}>【我已支付成功】</b> 按钮。
                  </div>
                  <div>
                      <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>失败</b>，请点击 <b
                      style={{color: 'red'}}>【取消】</b>重新购买。
                  </div>
              </div>
          </div>,
      onOk: () => {
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
        me.checkStateNum = 0;
        me.checkPayState(trade.trade_no,true);
        me.payMemberModal.destroy();
        me.payMemberModal = null;
      },
      onCancel: () => {
        message.warning('您取消了支付操作')
        me.payMemberModal.destroy();
        me.payMemberModal = null;
        if(me.setTimeoutCheckState != null){
          clearTimeout(me.setTimeoutCheckState);
          me.setTimeoutCheckState = null;
        }
      },
    })
    me.checkStateNum = 0;
    me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade.trade_no,false);},3000);
  }

  checkPayState = (trade_no,op=false) => {
    let me = this;
    me.checkStateNum = me.checkStateNum+1;
    let params ={
      trade_no:trade_no,
      id_user: localStorage.getItem('userId')
    }
    API_TradeOrderCheckState(params).then((response) => {
      if(response && response.data){
        if(response.data.code === 0){
          if(response.data.data.trade_no==trade_no){
            if(response.data.data.state==1){
              message.info('已支付成功！'+me.vip_content);
              me.props.onCancel();
              PB.emit('account', 'get.user_level', {});
              if(me.payMemberModal != null){
                me.payMemberModal.destroy();
                me.payMemberModal = null;
              }
              if(me.setTimeoutCheckState != null){
                clearTimeout(me.setTimeoutCheckState);
                me.setTimeoutCheckState = null;
              }
              if(me.props.up_by=='show_map'){
                //打开图谱时刷新页面
                window.location.reload();
              }
            }else{
              let checkNum = op?30:300;
              if(me.checkStateNum < checkNum){
                me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},op?1000:3000);
              }
            }
          }
        }else{
          if(op){
            message.info(response.data.msg, 3);
          }else{
            if(me.checkStateNum<20){
              me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
            }
          }
        }
      }else{
        if(op){
          message.info('网络不稳定，请稍后再试。', 3);
        }else{
          if(me.checkStateNum<10){
            me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
          }
        }
      }
    }).catch((e) => {
      if(op){
        message.info('网络不稳定，请稍后再试。', 3);
      }else{
        if(me.checkStateNum<10){
          me.setTimeoutCheckState = setTimeout(()=>{me.checkPayState(trade_no,op);},3000);
        }
      }
    });
  }

  onInputChange = e => {
    let me = this;
    const reg = /^\d*$/; // || e.target.value === ''
    if (reg.test(e.target.value)) {
      me.setState({recharge_money: e.target.value});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    if (prevProps.level !== this.props.level) {
      let me = this;
      if(this.props.level<2){
        me.setState({activeKey:'member_2',level:me.getProductLevel('member_2')});
      }else{
        me.setState({activeKey:'member_3',level:me.getProductLevel('member_3')});
      }
    }
  }

  componentDidMount() {
    let me = this;
    PB.emit('account', 'get.user_level', {});
    //me.props.bus.sub(me, 'upgrade', 'set.user_level', (data) => {
    //  me.setUserLevel(data);
    //});
  }

  componentWillUnmount() {
    //this.props.bus.remove(this);
  }

  render() {
    let me = this;
    return (
      <Modal
        width={660}
        centered={true}
        visible={me.props.visible}
        title={'会员充值'}
        footer={null}
        destroyOnClose={true}
        onCancel={me.props.onCancel}
      >
        <div style={{ height: '25.1rem',fontSize:'1.2rem' }}>
          <div style={{height: '20rem'}}>
            <p style={{fontSize:'1rem',marginTop:'1rem',marginLeft:'2rem',lineHeight:'2rem'}}>账户余额：{convertPrice(me.props.user_balance||0)}元</p>
            <p style={{fontSize:'1rem',marginTop:'1rem',marginLeft:'2rem',lineHeight:'2rem'}}>充值金额：
              <Input prefix="￥" suffix="RMB"
                        placeholder={'请输入充值金额'}
                        value={me.state.recharge_money}
                        onChange={me.onInputChange}
                        style={{height:'2.5rem',width:'10rem'}}
                      /></p>
            <p style={{fontSize:'0.8rem',marginTop:'3rem',marginLeft:'2rem'}}>提示：</p>
            <p style={{fontSize:'0.8rem',whiteSpace:'pre-wrap',marginLeft:'3rem',lineHeight:'2rem'}}>
            1、充值最小金额1元，充值金额必须为整数<br/>
            2、支付过程中浏览器会有几次跳转，支付完成之前请勿关闭浏览器，否则可能造成支付失败<br/>
            3、如充值后款项没有到账，请添加炬图客服官方微信（微信号：JoinmapAI），帮助处理<br/>
            </p>
          </div>
          <div style={{textAlign:'right'}}>
            <Button type={'primary'} onClick={() => me.payRecharge()} shape="round" size="large">立即充值</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

MemberRechargeModal.defaultProps = {
  bus: PB,
};

MemberRechargeModal.propType = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  bus: PropTypes.instanceOf(SimplePB),
  level: PropTypes.number,
  user_balance:PropTypes.number,
};

export default MemberRechargeModal;